$main-color: #ff6060;
$menu-color: rgba(240, 146, 40, 1);
$main-beige: #f3e7a2;
$font-color: #964712;
* {
  box-sizing: border-box;
}

.collapse {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  font-family: Montserrat;
  height: 52px;
  border-radius: 10px 10px 0px 0px;
  margin-top: 30px;
  padding: 10px 21px 10px 5%;
  border: solid !important;
  border: 3px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20.22px;
  margin-left: auto;
  margin-right: auto;
  z-index: 5;
  color: $menu-color;
  // box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.4);
  // @media (max-width: 470px) {
  //   margin-top: 18px;
  // }

  h2 {
    font-size: 24px;
    display: block;
    @media (max-width: 1920px) {
      font-size: 21px;
    }
    @media (max-width: 1536px) {
      font-size: 21px;
    }
    @media (max-width: 1366px) {
      font-size: 20px;
    }
    @media (max-width: 800px) {
      font-size: 12px;
    }
    @media (max-width: 650px) {
      font-size: 11px;
    }
    @media (max-width: 500px) {
      font-size: 10px;
    }
    @media (max-width: 414px) {
      font-size: 9px;
    }
    @media (max-width: 375px) {
      font-size: 8px;
    }
    @media (max-width: 360px) {
      font-size: 7px;
    }
  }
  i {
    display: block;
    font-size: 15px;
    @media (max-width: 1920px) {
      font-size: 15px;
    }
    @media (max-width: 1536px) {
      font-size: 15px;
    }
    @media (max-width: 1366px) {
      font-size: 15px;
    }
    @media (max-width: 800px) {
      font-size: 12px;
    }
    @media (max-width: 650px) {
      font-size: 11px;
    }
    @media (max-width: 500px) {
      font-size: 10px;
    }
    @media (max-width: 414px) {
      font-size: 9px;
    }
    @media (max-width: 375px) {
      font-size: 8px;
    }
    @media (max-width: 360px) {
      font-size: 7px;
    }
  }

  .downwards {
    transform-origin: center;
    transform: rotate(-180deg);
    transition: 0.4s;
  }
  .upwards {
    transform-origin: center;
    transform: rotate(0deg);
    transition: 0.4s;
  }
}

.content {
  height: 0px;
  width: 1023px;
  border-radius: 0px 0px 10px 10px;
  box-sizing: border-box;
  // background-image: url('../../assets/texturedoomcontent.jpg');
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  transition: height ease 0.4s;
  position: relative;
  // box-shadow: 0em 0 0.2em olive;

  .description {
    padding: 30px 15px 15px 5%;
    font-family: Montserrat;
    font-weight: 20px;
    color: $main-color;
    line-height: 20 px;
    z-index: 4;

    a {
      z-index: 7;
    }
    .dark a {
      color: orange;
    }
    .light a {
      color: $font-color;
    }

    ul {
      list-style-type: none;
      padding-left: 0px;
      margin: 0px;
      li {
        height: 34px;
        padding-top: 7px;
        padding-left: 40%;
      }
      li:hover {
        background: url('../../assets/unicursal-hexagram-flashy22.png')
          no-repeat left center;
      }
    }
  }
}

.small {
  width: 90%;
}
