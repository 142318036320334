* {
  box-sizing: border-box;
}

.collapse {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  font-family: Montserrat;
  height: 52px;
  border-radius: 10px 10px 0px 0px;
  margin-top: 30px;
  padding: 10px 21px 10px 5%;
  border: solid !important;
  border: 3px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20.22px;
  margin-left: auto;
  margin-right: auto;
  z-index: 5;
  color: rgb(240, 146, 40);
}
.collapse h2 {
  font-size: 24px;
  display: block;
}
@media (max-width: 1920px) {
  .collapse h2 {
    font-size: 21px;
  }
}
@media (max-width: 1536px) {
  .collapse h2 {
    font-size: 21px;
  }
}
@media (max-width: 1366px) {
  .collapse h2 {
    font-size: 20px;
  }
}
@media (max-width: 800px) {
  .collapse h2 {
    font-size: 12px;
  }
}
@media (max-width: 650px) {
  .collapse h2 {
    font-size: 11px;
  }
}
@media (max-width: 500px) {
  .collapse h2 {
    font-size: 10px;
  }
}
@media (max-width: 414px) {
  .collapse h2 {
    font-size: 9px;
  }
}
@media (max-width: 375px) {
  .collapse h2 {
    font-size: 8px;
  }
}
@media (max-width: 360px) {
  .collapse h2 {
    font-size: 7px;
  }
}
.collapse i {
  display: block;
  font-size: 15px;
}
@media (max-width: 1920px) {
  .collapse i {
    font-size: 15px;
  }
}
@media (max-width: 1536px) {
  .collapse i {
    font-size: 15px;
  }
}
@media (max-width: 1366px) {
  .collapse i {
    font-size: 15px;
  }
}
@media (max-width: 800px) {
  .collapse i {
    font-size: 12px;
  }
}
@media (max-width: 650px) {
  .collapse i {
    font-size: 11px;
  }
}
@media (max-width: 500px) {
  .collapse i {
    font-size: 10px;
  }
}
@media (max-width: 414px) {
  .collapse i {
    font-size: 9px;
  }
}
@media (max-width: 375px) {
  .collapse i {
    font-size: 8px;
  }
}
@media (max-width: 360px) {
  .collapse i {
    font-size: 7px;
  }
}
.collapse .downwards {
  transform-origin: center;
  transform: rotate(-180deg);
  transition: 0.4s;
}
.collapse .upwards {
  transform-origin: center;
  transform: rotate(0deg);
  transition: 0.4s;
}

.content {
  height: 0px;
  width: 1023px;
  border-radius: 0px 0px 10px 10px;
  box-sizing: border-box;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  transition: height ease 0.4s;
  position: relative;
}
.content .description {
  padding: 30px 15px 15px 5%;
  font-family: Montserrat;
  font-weight: 20px;
  color: #ff6060;
  line-height: 20 px;
  z-index: 4;
}
.content .description a {
  z-index: 7;
}
.content .description .dark a {
  color: orange;
}
.content .description .light a {
  color: #964712;
}
.content .description ul {
  list-style-type: none;
  padding-left: 0px;
  margin: 0px;
}
.content .description ul li {
  height: 34px;
  padding-top: 7px;
  padding-left: 40%;
}
.content .description ul li:hover {
  background: url("../../assets/unicursal-hexagram-flashy22.png") no-repeat left center;
}

.small {
  width: 90%;
}/*# sourceMappingURL=collapse.css.map */